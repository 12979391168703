import { Platform } from 'react-native';
import Config from "react-native-config";

const PLATFORM = Platform.OS === 'web' ? process.env.PLATFORM : Platform.OS // used only to diffrentiate between web and windows

const ARYA_ORG_ID = "2";

const RICE_GRAIN_ID = "18";
const MANUAL_GRAIN_ANALYSIS = "MANUAL_GRAIN_ANALYSIS";
const API_BASE_URL = () => Platform.OS === 'web' ? process.env.API_BASE_URL: Config.API_BASE_URL;

const TEST_API_BASE_URL = () => Platform.OS === 'web' ? process.env.TEST_API_BASE_URL: Config.TEST_API_BASE_URL;

const S3_BUCKET_URL = () => Platform.OS === 'web' ? process.env.S3_BUCKET_URL: Config.S3_BUCKET_URL;

const S3_BUCKET_NAME = () => Platform.OS === 'web' ? process.env.S3_BUCKET_NAME: Config.S3_BUCKET_NAME;

const USER_POOL_ID = () => Platform.OS === 'web' ? process.env.USER_POOL_ID: Config.USER_POOL_ID;

const USER_POOL_WEB_CLIENT_ID = () => Platform.OS === 'web' ? process.env.USER_POOL_WEB_CLIENT_ID: Config.USER_POOL_WEB_CLIENT_ID;

const IS_TEST_ENV = () => Platform.OS === 'web' ? process.env.IS_TEST === 'true': Config.IS_TEST === 'true';

const USER_POOL_REGION = 'us-west-2'

const ADMIN_EMAIL = "inweonapp@gmail.com"

const SCAN_TIMEOUT = 3 * 60 * 1000;
const UPLOAD_TIMEOUT = 1 * 60 * 1000;
const MANUAL_UPLOAD_TIMEOUT = 1 * 10 * 1000;
const DEFAULT_SCAN_DPI = 600;

const APP_THEME = {
    primaryColor: '#000',
}

export {
    API_BASE_URL,
    TEST_API_BASE_URL,
    S3_BUCKET_URL,
    USER_POOL_ID,
    S3_BUCKET_NAME,
    USER_POOL_WEB_CLIENT_ID,
    IS_TEST_ENV,
    ADMIN_EMAIL,
    MANUAL_GRAIN_ANALYSIS,
    PLATFORM,
    RICE_GRAIN_ID,
    ARYA_ORG_ID,
    USER_POOL_REGION,
    APP_THEME,
    SCAN_TIMEOUT,
    UPLOAD_TIMEOUT,
    MANUAL_UPLOAD_TIMEOUT,
    DEFAULT_SCAN_DPI
}