import { IManualJobEntryInput } from "./Job";
import { JobStatus } from "./JobStatus";

// used when image is not available (for eg., new job)
export interface ICreateJobRequest {
    jobId: string;
    test?: boolean;
    object_key?: string;
    source: string;
    manualEntryInput? : IManualJobEntryInput;
    location: string;
}

// used when image already exists (for eg., retrial or test jobs)
export interface IQueueJobRequest {
    jobId: string;
}

export interface IJobRequest {
    jobId: string;
    grainId: string;
    profileId: string;
    object_key?: string;
    deviceId?: string;
    info?: { [key: string]: string };
    additionalAttributes?: IJobAdditionalAttributes;
    source: string;
    results?: IManualJobEntryInput;
}

export interface IJobAdditionalAttributes {
    rice_process: string;
    rice_size: string;
    rice_color: string;
    scannerId?: string;
}

export interface IUpdateJobRequest {
    jobId: string;
    info: { [key: string]: string };
}

export interface IUpdateJobResultRequest {
    jobId: string;
    result_field: string;
    data: any[];
}

export interface IUpdateRpiJobStatusRequest {
    jobId: string;
    status: JobStatus;
}

export interface IUserJobListRequest {
    username: string;
    from?: number;
    to?: number;
}

export interface IOrgJobListRequest {
    orgId: string;
    from?: number;
    to?: number;
    centerId?: string;
    grainId?: string;
    jobId?: string;
}