import { ICenter } from "./Center";
import { IGrainWithProfiles } from "./GrainProfile";
import { IOrg } from "./Org";
import { IAllUserSettingsResponse } from "./UserSettings";

export interface IUserProfileResponse {
    cached: boolean;
    expiry: Date;
    profile: IUserProfile;
}

export interface IUserProfile {
    username: string;
    org: IOrg;
    center: ICenter;
    surveyor: { [key: string]: string; };
    grains: IGrainWithProfiles[];
    settings: IAllUserSettingsResponse;
    groups: string[];
    dongle?: IDongleDetails;
}

export interface IDongleDetails {
    dongleId: string;
}