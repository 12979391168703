import { IManualJobEntryInput } from "./Job";

export interface IAnalyseUploadRequest {
    jobId: string;
    isBase64: boolean;
    dataObject: any;
    source: string;
    location: string;
}

export interface IAnalyseDoubleSidedUploadRequest {
    jobId: string;
    isBase64: boolean;
    dataObject: any[];
    source: string;
    location: string;
}

export interface IAnalyseScanRequest {
    jobId: string;
    source: string;
    location: string;
}

export interface IAnalyseManualJobRequest {
    jobId: string;
    manualJobEntryInput: IManualJobEntryInput;
    location: string;
}

export interface IAnalyseRetrialRequest {
    jobId: string;
}