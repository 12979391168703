import { JobStatus } from "./JobStatus";

export interface IRpiWifiConfig {
  wifiSSID: string;
  wifiPassword: string;
}

export interface IRpiStatus {
  wifiStatus: string;
  scannerStatus: string;
  jobId: string | undefined;
  jobStatus: JobStatus;
  config?: IRpiWifiConfig;
}

