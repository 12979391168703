import { IJobAdditionalAttributes } from "./JobRequest";
import { JobStatus } from "./JobStatus";

export interface IManualJobEntryInput {
    grain_count: string;
}

export interface IJob {
    createdAt: number;
    updatedAt?: number;
    username?: string;
    jobId: string;
    grainId: string;
    profileId: string;
    jobStatus: JobStatus;
    input_object_key?: string;
    results?: IJobResult;
    info?: { [key: string]: string };
    productName?: string;
    additionalAttributes?: IJobAdditionalAttributes;
}

export interface IQueueRequest {
    jobId: string;
}

export interface IOrgJobListItem {
    jobId: string;
    jobStatus: JobStatus;
    grainId: string;
    username?: string;
    centerName?: string;
    createdAt: number;
}

export interface IUserJobListItem {
    jobId: string;
    variety?: string;
    jobStatus: JobStatus;
    grainId: string;
    createdAt: number;
}

export interface IJobResult {
    avg_aspect_ratio: number;
    std_area_base: number;
    std_aspect_ratio: number;
    avg_width: number;
    avg_length: number;
    avg_length_of_broken_grains?: number;
    grain_count: number;
    whiteness_index?: number;
    std_width: number;
    avg_area_base: number;
    jobStatus: string;
    std_length: number;
    indi_grain_csv: string;
    rejection_analysis_csv: string;
    rejection_analysis_display_csv: string; // for L10 users
    labeled_image: string;
    good_grain_percent: number;
    rejection_percent: number;
    particle_distribution_csv: string; // for L10 users
    cumulative_analysis_csv: string; // for L10 users
    scanned_images: string[];
}

export interface IJobs {
    Items: IJob[];
}

export interface IJobListTimestampRange {
    readonly from: number;
    readonly to: number;
}